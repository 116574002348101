<template>
  <div>
      <!-- Start of breadcurmb section
        ============================================= -->
        <section id="saasio-breadcurmb" class="saasio-breadcurmb-section">
            <div class="container">
                <div class="breadcurmb-title text-center">
                    <h1>TARIFS</h1>
                </div>
                <div class="breadcurmb-item-list text-center ul-li">
                 <ul class="saasio-page-breadcurmb">
                     <li><router-link to="/">ACCUEIL</router-link></li>
                     <li><a href="javascript:void(0)"> TARIFS</a></li>
                 </ul>
             </div>
         </div>
     </section>
    <!-- End of breadcurmb section ============================================= --> 

        <!-- Pricing  -->
        <Pricing />

        <!-- Questions  -->
        <Questions />


  </div>
</template>

<script>
import Pricing from '../components/Pricing.vue';
import Questions from '../components/Questions.vue';
export default {
    name : "Tarif",
    components: {
      Pricing,
      Questions
  }
}
</script>
