<template>
    <section id="s2-faq" class="s2-faq_section relative-position">
        <div class="container">
            <div class="saas_two_section_title saas2-headline text-center">
                <span class="title_tag">
                    Foire aux Questions
                </span>
               <!--  <h2>
                    We have some FAQ
                </h2> -->
            </div>
            <div class="s2_faq_content">
                <div class="accordion" id="accordionExample">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="s2_faq">
                                <div class="s2_faq-header" id="headingOne">
                                    <button class="collapsed" data-toggle="collapse" data-target="#collapseOne" aria-controls="collapseOne">
                                        01. Le CRM peut-il accompagner la croissance de mon entreprise ?
                                    </button>
                                </div>
                                <div id="collapseOne" class="collapse" data-parent="#accordionExample">
                                    <div class="s2_faq-body">
                                        Lorsque vous comparez des CRM, une des choses les plus importantes – et pourtant facile à oublier – est de savoir
                                            si l'outil peut évoluer en même temps que vos affaires. Vous pouvez être une start-up aujourd'hui,
                                            mais vous seriez surpris de voir à quelle vitesse un bon CRM peut vous faire atteindre tous vos objectifs.
                                            Pour éviter d'avoir à changer de système dans un an ou deux, voyez quels types de fonctionnalités
                                            sont disponibles avec tous les plans d'abonnement et non pas seulement avec celui qui vous intéresse pour le moment.
                                    </div>
                                </div>
                            </div>
                            <div class="s2_faq">
                                <div class="s2_faq-header" id="headingTwo">
                                    <button class="collapsed" data-toggle="collapse" data-target="#collapseTwo">
                                        02. Quels sont les types de personnalisation proposés ?
                                    </button>
                                </div>
                                <div id="collapseTwo" class="collapse" data-parent="#accordionExample">
                                    <div class="s2_faq-body">
                                        Chaque CRM a besoin d'être plus ou moins personnalisable pour s'adapter parfaitement à votre travail
                                            et à vos processus de vente. Créer des champs ou des pipelines personnalisés est standard,
                                            mais l'outil vous permet-il d'automatiser certaines portions de votre flux de travail 
                                            particulier ? Consultez les comparatifs de prix des différents CRM pour voir auprès de 
                                            chaque fournisseur combien vous devrez débourser pour les différentes personnalisations 
                                            qui vous sont nécessaires.
                                    </div>
                                </div>
                            </div>
                            <div class="s2_faq">
                                <div class="s2_faq-header" id="headingThree">
                                    <button class="collapsed" data-toggle="collapse" data-target="#collapseThree">
                                        03. Quelles sont les capacités de constitution de rapports ?
                                    </button>
                                </div>
                                <div id="collapseThree" class="collapse" data-parent="#accordionExample">
                                    <div class="s2_faq-body">
                                        Les rapports sur les ventes sont un élément crucial pour l'amélioration de 
                                        vos résultats et la motivation de votre équipe. Si vous créez un grand nombre de rapports pour 
                                        votre entreprise, cherchez un CRM capable de les générer sans intervention manuelle de votre part.
                                            Mieux encore, essayez d'en trouver un qui vous propose des tableaux de bord actualisés en direct,
                                            afin de toujours vérifier que vous êtes sur la bonne trajectoire pour atteindre vos objectifs. 
                                            Si vos rapports sont à la fois élégants et faciles à réaliser, vous vous surprendrez peut-être à 
                                            les utiliser davantage.
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div class="col-lg-6 col-md-12">
                            <div class="s2_faq">
                                <div class="s2_faq-header" id="heading5">
                                        <button class="collapsed" data-toggle="collapse" data-target="#collapse5" aria-controls="collapse5">
                                        04. Quel type d'assistance est proposé ?
                                    </button>
                                </div>
                                <div id="collapse5" class="collapse" data-parent="#accordionExample">
                                    <div class="s2_faq-body">
                                        Si une grosse vente peut être perdue à cause d’une erreur de logiciel, 
                                        vous ne pouvez pas vous permettre d’attendre cinq jours ouvrés que l’on vous
                                            réponde par courriel. Cherchez un système qui vous offre un chat en direct ou une assistance téléphonique disponibles
                                            à vos heures de fonctionnement et dont la réputation du service client est exemplaire.<br/>

                                        Conseil: Cherchez un CRM qui propose une assistance 24h/24 7j/7 pour être sûr que vos heures de travail soient 
                                        intégralement couvertes, quel que soit votre fuseau horaire.
                                    </div>
                                </div>
                            </div>
                            <div class="s2_faq">
                                <div class="s2_faq-header" id="heading6">
                                    <button class="collapsed" data-toggle="collapse" data-target="#collapse6">
                                        05. Est-il simple à utiliser, et sera-t-il facile de former mon équipe ?
                                    </button>
                                </div>
                                <div id="collapse6" class="collapse" data-parent="#accordionExample">
                                    <div class="s2_faq-body">
                                        Quand vous les comparez aux solutions alternatives, feuilles de calcul ou crayon et papier, 
                                        la plupart des systèmes de CRM peuvent paraître compliqués. Toutefois, le système idéal pour vous doit vous 
                                        aider à faire plus en moins de temps. La clé est d'en trouver un qui soit à la fois puissant et intuitif.
                                        Les outils qui débordent d'alarmes et de notifications peuvent séduire au premier abord, mais s'il 
                                        faut à votre équipe des mois pour apprendre à les manipuler, c'est beaucoup de temps et de productivité perdus.
                                    </div>
                                </div>
                            </div>
                            <div class="s2_faq">
                                <div class="s2_faq-header" id="heading7">
                                    <button class="collapsed" data-toggle="collapse" data-target="#collapse7">
                                        06. Comment puis-je essayer Smartsites ?
                                    </button>
                                </div>
                                <div id="collapse7" class="collapse" data-parent="#accordionExample">
                                    <div class="s2_faq-body">
                                        Contactez-nous par e-mail ou par téléphone et on vous donnera la possibilité
                                         d'explorer Smartsites pour une période d’essai.
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'Questions',
  props: {
    msg: String
  }
}
</script>

